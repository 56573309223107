import { corientAccountInformationDefaults } from '~/components/corient/NewSubscriptionForm/accountInformation/form/corientAccountInformationDefaults';
import corientAllocationBuildDefaults from '~/components/corient/NewSubscriptionForm/allocationBuild/form/corientAllocationBuildDefaults';
import { allocationNewIssuesDefaults } from '~/components/corient/NewSubscriptionForm/InvestorQuestionnaire/CorientAllocationOffNewIssues/AllocationNewIssuesQuestions/form/allocationNewIssuesDefaults';
import corientInvestorQuestionnaireDefaults from '~/components/corient/NewSubscriptionForm/InvestorQuestionnaire/CorientInvestorQuestionnaire/form/corientInvestorQuestionnaireDefaults';
import { corientSubscriberInformationDefaults } from '~/components/corient/NewSubscriptionForm/subscriberInformation/CorientSubscriberInformation/form/corientSubscriberInformationDefaults';
import { erisaQuestionnaireDefaults } from '../SubscriberInformation/FlagshipERISAQuestionnaireSection/form/erisaQuestionnaireDefaults';
import disregardedEntitiesDefaults from '../SubscriberInformation/AuthorizedEntities/DisregardedEntities/form/disregardedEntitiesDefaults';
import offeringDocsFormDefaults from '../UploadDocuments/offeringDocuments/formFields/offeringDocsDefaults';
import {
  AccountInformationData,
  AllocationBuildData,
  AuthorizedContactsData,
  InvestorQuestionnaireData,
  NewSubscriptionFormData,
  SubscriberInformationData,
  UploadDocumentData,
} from './types';

export const COUNTRY_DEFAULT_VALUE = 'United States';
export const CANADA_COUNTRY_DEFAULT_VALUE = 'Canada';

export const investorQuestionnaireDefaults: InvestorQuestionnaireData = {
  subscriber_name: '',
  fe_only_subscriber_name: '',
  confirm_tax_status: false,
  fe_only_waive_tax_advantage: false,
  us_nationality: '',
  fe_only_canadian_nationality: '',
  trust_account: '',
  tax_advantaged: '',
  entity_type: '',
  joint_account_type: '',
  individual_or_joint: '',
  individual_or_entity: '',
  ever_invested: '',
  frequency_investments: '',
  financial_knowledge: '',
  account_type: '',
  glasfunds_tax_status: '',
  vehicle: 'FLAGSHIP',
  accredited_investor_status: '',
  fiduciary_name: '',
  qualified_purchaser: '',
  ...allocationNewIssuesDefaults,
  ...corientInvestorQuestionnaireDefaults,
};

export const investor1AddressInfo = {
  investor_address_line_one: '',
  investor_address_line_two: '',
  investor_city: '',
  investor_country: '',
  investor_state: '',
  investor_zip_code: '',
};
export const investor2AddressInfo = {
  investor_2_address_line_one: '',
  investor_2_address_line_two: '',
  investor_2_city: '',
  investor_2_country: '',
  investor_2_state: '',
  investor_2_zip_code: '',
};

export const accountInformationDefaults: AccountInformationData = {
  tin_1: '',
  subscriber_citizenship: '',
  backup_withholding: '',
  tin_2: '',
  phone_number: '',
  ...investor1AddressInfo,
  held_at_custodian: '',
  ai_investor_canadian_certificate_selected_option: '',
  pc_investor_canadian_certificate_selected_option: '',
  same_address_for_investor_2: '',
  ...investor2AddressInfo,
  ai_investor_2_canadian_certificate_selected_option: '',
  pc_investor_2_canadian_certificate_selected_option: '',
  bank_name: '',
  fe_only_is_us_bank: null,
  account_name: '',
  account_holder_address: '',
  custodian_name: '',
  aba_number: '',
  swift_code: '',
  account_number: '',
  ffc_name: '',
  ffc_number: '',
  tamarac_id: '',
  black_diamond_account_number: '',
  orion_account_number: '',
  orion_client_id: '',
  orion_registration_id: '',
  intermediary_bank_name: '',
  fe_only_intermediary_is_us_bank: null,
  intermediary_aba_routing_number: '',
  intermediary_swift_code: '',
  ...corientAccountInformationDefaults,
};

export const FLAGSHIP_SIGNER_DEFAULT = {
  name: '',
  title: '',
  email: '',
  birthdate: '',
  country_of_birth: '',
};
/**
 * TODO change defaults to be chosen conditionally once the validation rules are
 * correctly set inside the stepper as we currently use the defaults from here
 */
export const subscriberInformationDefaults: SubscriberInformationData = {
  // @ts-ignore: This spread always overwrites this property
  signers: [FLAGSHIP_SIGNER_DEFAULT],
  is_unemployed: false,
  reg_entity: '',
  grantor_name: '',
  employer_name: '',
  employer_address_one: '',
  employer_address_two: '',
  employer_country: '',
  employer_city: '',
  employer_state: '',
  employer_zip_code: '',
  employer_phone_number: '',
  date_inc: '',
  erisa_or_4975: '',
  entity_country: '',
  entity_exempt_income_tax_reason: '',
  other_exempt_reason: '',
  entity_state: '',
  type_of_benefit_plan: '',
  not_insurance_percentage_value: '',
  insurance_percentage_value: '',
  llc_structure: '',
  k1_designation_of_corporation: '',
  foreign_partners: '',
  ...erisaQuestionnaireDefaults,
  ...corientSubscriberInformationDefaults,
  ...disregardedEntitiesDefaults,
};

export const authorizedContactsDefaults: AuthorizedContactsData = {
  investor_group_id: '',
  contacts: [
    {
      name: '',
      title: '',
      email: '',
      distrib: false,
      aig_default_check_distribution: false,
      copy_docusign: false,
      aig_default_check_cc_email: false,
    },
  ],
  selected_designated_signer: null,
  authorized_signers: [],
  fe_only_subscriber_signers: [],
  fe_only_selected_investor_group:
    {} as AuthorizedContactsData['fe_only_selected_investor_group'],
  fe_only_auth_signers: [],
};

export const allocationBuildDefaults: AllocationBuildData = {
  commitments: [{ amount: '', fund_id: '' }],
  cash: '',
  ...corientAllocationBuildDefaults,
};

export const uploadDocumentsDefaults: UploadDocumentData = {
  files: [],
  execution_type: 'internal',
  ...offeringDocsFormDefaults,
};

export const newSubscriptionDefaults: NewSubscriptionFormData = {
  ...investorQuestionnaireDefaults,
  ...accountInformationDefaults,
  ...subscriberInformationDefaults,
  ...authorizedContactsDefaults,
  ...allocationBuildDefaults,
  ...uploadDocumentsDefaults,
} as const;

export type NewSubscriptionKeys = keyof typeof newSubscriptionDefaults;
